import AppSearchAPIConnector from "@elastic/search-ui-app-search-connector";
import React from "react";
import {
  ErrorBoundary,
  Facet,
  SearchProvider,
  SearchBox,
  Results,
  PagingInfo,
  ResultsPerPage,
  Paging,
  WithSearch
} from "@elastic/react-search-ui";
import {
  BooleanFacet,
  Layout,
  SingleLinksFacet,
  SingleSelectFacet
} from "@elastic/react-search-ui-views";
import "@elastic/react-search-ui-views/lib/styles/styles.css";
import { SearchDriverOptions } from "@elastic/search-ui";
const connector = new AppSearchAPIConnector({
  searchKey: "search-op7xwtotgu3on4uq3k6194xb",
  engineName: "search-dairyreporter-constrained",
  endpointBase: "https://937706e1c5e74242992041e269c0212e.ent-search.europe-west4.gcp.elastic-cloud.com"
});

const now = new Date().toISOString(); // Current date in RFC 3339 format

const oneDayAgo = new Date();
oneDayAgo.setDate(oneDayAgo.getDate() - 1);
const today = oneDayAgo.toISOString(); // Today

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
const thisWeek = oneWeekAgo.toISOString(); // This Week

const oneMonthAgo = new Date();
oneMonthAgo.setMonth(oneMonthAgo.getMonth() - 1);
const thisMonth = oneMonthAgo.toISOString(); // This Month

const config: SearchDriverOptions = {
  alwaysSearchOnInitialLoad: true,
  apiConnector: connector,
  hasA11yNotifications: true,
  searchQuery: {
    result_fields: {
      title: { raw: {} },
      "news-subtitle": {
        snippet: {
          fallback: true
        }
      },
      "news-content": {
        snippet: {
          fallback: true
        }
      },
      // topics: { raw: {} },
      url: { raw: {} },
      last_crawled_at: { raw: {} }
    },
    search_fields: {
      title: {
        weight: 2
      },
      "news-subtitle": {
        weight: 3,
      },
      "news-content": {
        weight: 5,
      },
    },
    disjunctiveFacets: [""],
    facets: {
      last_crawled_at: {
        type: "range",
        "ranges": [
          {
            from: today,
            name: "Today"
          },
          {
            from: thisWeek,
            name: "This Week"
          },
          {
            from: thisMonth,
            name: "This Month"
          },
          {
            name: "Older",
            to: thisMonth
            // No 'to' value means it covers everything older than 'from'
          }
        ]
      },
    }
  }
};
export default function App() {
  return (
    <SearchProvider config={config}>
      <WithSearch
        mapContextToProps={({ wasSearched }) => ({
          wasSearched
        })}
      >
        {({ wasSearched }) => {
          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={<SearchBox searchAsYouType={true} debounceLength={1} />}
                  sideContent={wasSearched && <div>
                    <Facet
                      field="last_crawled_at"
                      label="How Old"
                    />
                  </div>}
                  bodyContent={
                    <Results
                      titleField="title"
                      urlField="url"
                      thumbnailField="image_url"
                      shouldTrackClickThrough
                    />
                  }
                  bodyHeader={
                    <React.Fragment>
                      {wasSearched && <PagingInfo />}
                      {wasSearched && <ResultsPerPage />}
                    </React.Fragment>
                  }
                  bodyFooter={<Paging />}
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
}